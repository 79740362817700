import React from "react"
import __c from "../utilities/combineClasses"
import ls from "local-storage"

import style from "./sign-up-process.module.scss"

const sharpSpringEndpoint =
  "https://app-3QNNTJ5PD0.marketingautomation.services/webforms/receivePostback/MzawMLEwMTIzAQA/08ac5808-4a3c-4fc7-a5d2-2fb8256c2c0a/jsonp"

export default class UkForm extends React.Component {
  constructor(props) {
    super(props)
    this.componentID = Math.floor(Math.random() * 1000)
    this.state = {
      name: "",
      org: "",
      email: "",
      phone: "",
      status: `unsent`,
      errorMessage: ``,
      affiliateCode: ls.get(`affiliateCode`) || `AWM`,
    }
  }

  handleInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  composeRequestBody = () => {

  }

  getTrackingCookie = () => {
    var b = document.cookie.match('(^|;)\\s*' + '__ss_tk' + '\\s*=\\s*([^;]+)');
    return b ? b.pop() : '';
}

  trySubmit = (event) => {
    event.preventDefault()
    this.setState({
      status: `loading`,
    })

    var tracking = this.getTrackingCookie()
    var formHeaders = new Headers()
    formHeaders.append(
      "Content-Type",
      "application/x-www-form-urlencoded;charset=UTF-8"
    )
    formHeaders.append("X-Requested-With", "XMLHttpRequest")
    var urlencoded = new URLSearchParams()
    let nameArray = this.state.name.split(" ")
    urlencoded.append(`given_name`, nameArray.shift())
    urlencoded.append(`surname`, nameArray.join(" "))
    urlencoded.append(`company`, this.state.org)
    urlencoded.append(`email`, this.state.email)
    urlencoded.append(`phone`, this.state.phone)
    urlencoded.append(`affiliate`, this.state.affiliateCode)
    urlencoded.append(`trackingid__sb`, tracking)
    fetch(
      `${sharpSpringEndpoint}/jsonp/?${urlencoded.toString()}`,
      {
        method: "GET",
        mode: "no-cors",
        headers: formHeaders,
        redirect: "follow",
      }
    ).then((response) => {
      console.log(response)
      this.setState({
        status: `success`,
      })
    })
    .catch((err) => {
      console.error(err)
      this.setState({
        status: `error`,
        errorMessage: err.message,
      })
    })
  }

  render = () => {
    const { className } = this.props
    return (
      <section
        className={__c([style.component, className])}
        data-status={this.state.status}
        data-theme="callout"
      >
        <main className={__c(style.main)} data-theme="extra-callout">
          <form action="">
            <div
              className={__c([
                style.formPage,
                style.formStateUnsent,
                this.state.status === `unsent` || this.state.status === `error`
                  ? style.formPageActive
                  : ``,
              ])}
            >
              <div className={__c([`fieldset`, style.fieldSet])}>
                <label className={__c(style.fieldLabel)} htmlFor="name">
                  Your name
                </label>
                <input
                  autoComplete="off"
                  name="name"
                  id={`name-${this.componentID}`}
                  type="text"
                  value={this.state.name}
                  onChange={this.handleInputChange}
                  disabled={this.state.status === `success`}
                />
              </div>
              <div className={__c([`fieldset`, style.fieldSet])}>
                <label className={__c(style.fieldLabel)} htmlFor="org">
                  Organization
                </label>
                <input
                  autoComplete="off"
                  name="org"
                  id={`org-${this.componentID}`}
                  type="text"
                  value={this.state.org}
                  onChange={this.handleInputChange}
                  disabled={this.state.status === `success`}
                />
              </div>
              <div className={__c([`fieldset`, style.fieldSet])}>
                <label className={__c(style.fieldLabel)} htmlFor="email">
                  Email
                </label>
                <input
                  autoComplete="off"
                  name="email"
                  id={`email-${this.componentID}`}
                  type="email"
                  value={this.state.email}
                  onChange={this.handleInputChange}
                  disabled={this.state.status === `success`}
                />
              </div>
              <div className={__c([`buttonset`, style.fieldSet])}>
                <button
                  className={__c(`aside-button`)}
                  onClick={this.trySubmit}
                  disabled={this.state.status === `success`}
                >
                  Sign Up
                </button>
              </div>
            </div>
          </form>
        </main>
        <footer className={__c([style.footer, style.messages])}>
          <div
            className={__c([
              style.message,
              this.state.status === `invalid` ? style.isActive : false,
            ])}
          >
            <p>
              Please check the indicated fields above to make sure everything is
              correct and entered fully.
            </p>
          </div>
          <div
            className={__c([
              style.message,
              this.state.status === `loading` ? style.isActive : false,
            ])}
          >
            <p>Loading...</p>
          </div>
          <div
            className={__c([
              style.message,
              this.state.status === `error` ? style.isActive : false,
            ])}
          >
            <p>
              We couldn&apos;t send the form. Please call{" "}
              <a href="tel:+443337721488">(0333) 772 1488</a> reach us directly.
            </p>
          </div>
          <div
            className={__c([
              style.message,
              this.state.status === `success` ? style.isActive : false,
            ])}
          >
            <p>
              Thanks for your note! We&apos;ll reach out shortly.
            </p>
          </div>
        </footer>
      </section>
    )
  }
}