import React from "react"
import __c from "../utilities/combineClasses"
import Layout from "../components/layout"
import SEO from "../components/seo"
import UkForm from "../components/uk-form"

import style from "./contact.module.scss"

export default () => {
  return (
    <Layout className={style.gridLayout}>
      <SEO title="Contact Sales" />
      <article className={__c(style.article)}>
        <header className={__c(style.header)}>
          <h1 className={__c(style.pageTitle)}>Contact Sales</h1>
          <div className={__c(style.introduction)}>
            <p>
              We're here to help you make the right choice for your marketing
              group, big or small:
            </p>
            <ul>
              <li>Custom solutions</li>
              <li>Pricing structure</li>
              <li>Helpful resources</li>
            </ul>
            <p>
              Use this form to send us your contact details and we'll arrange a
              time to talk or provide a demonstration of what VariaMetrix can do
              for you.
            </p>
            <p>
              If you need immediate assistance, call us in the UK:{" "}
              <a href="tel:+443337721488">(0333) 772 1488</a>
            </p>
          </div>
        </header>
        <main className={__c(style.main)}>
          <UkForm className={style.form} />
        </main>
      </article>
    </Layout>
  )
}
